<template>
  <div>
    <TitleWrapper :filter-option="true" title="Exam Result" :display-breadcrumb="true">
      <template v-slot:filterItems>
        <h2
          v-i18n="result"
          class="mb-4 text-text-color font-roboto font-normal text-base sm:text-xl"
        >
          Search
        </h2>
      </template>
    </TitleWrapper>
    <div
      :class="$style.minHieght80"
      class="main-content bg-primary-white rounded-t-lg mt-5 p-5 border-border-color-lighter border"
    >
      <div class="flex justify-between">
        <div v-i18n="result" class="font-roboto text-text-color text-lg opacity-100">
          Exam Results
        </div>
      </div>
      <TableWrapper v-if="!isLoading">
        <THead>
          <TRHead>
            <TH v-for="(head, index) in tableHeaders" :key="index">
              <span v-i18n="result">
                {{ head }}
              </span>
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(res, index) in resultsList" :key="index">
            <TD>
              {{ index + skip }}
            </TD>
            <TD>
              <div class="flex">
                <span class="flex items-center">
                  <img
                    class="mr-4 rounded-full bg-red-100"
                    :class="[!res.image && 'p-2']"
                    :src="res.image ? res.image : require('@assets/images/admin/student.svg')"
                    alt="student"
                  />
                </span>
                <span class="pl-3 mt-3">{{ res.full_name }}</span>
              </div>
            </TD>
            <TD>
              <span
                class="text-primary-green cursor-pointer"
                @click="redirectToStudentResultPage(res)"
              >
                {{ res.pass_count }} passed & {{ res.fail_count }} failed
              </span>
            </TD>
            <TD>
              {{ res.total_marks }}
            </TD>
            <TD>
              <p :style="itemStyleStatus(res.status)">
                {{ res.status }}
              </p>
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>

      <div v-else class="mt-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="resultCount && resultCount > 10"
        v-show="!isLoading"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(resultCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import exams from '@/src/state/modules/exams/exams'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import { objectKeyIntoCapitalizeWords } from '@utils/generalUtil'
import scrollMixin from '@src/mixins/scroll-mixin'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, scrollMixin],
  data() {
    return {
      resultsList: [],
      isLoading: false,
      modalButtonLoading: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      result: 'result',
      currentUser: {},
      currentUserPosition: '',
      filteredRecordLimit: 10,
      showPagination: false,
      tableHeaders: ['Std Id', 'Student Name', 'Result Summary', 'Total Marks', 'Status'],
      resultCount: 0,
      skip: 0,
    }
  },
  watch: {},
  created() {
    this.filterRecord()
    this.setRightBar()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent', 'setShowModal', 'setShowUiModal']),
    ...mapActions('exams', ['examResult']),

    setRightBar(info) {
      const rightBarContent = {
        header: {
          title: 'Exam',
        },

        info: info,
        bargraph: [
          {
            barGraphTitle: this.$t('rightBar.Summary'),
            barGraphList: [
              { info: 'Lectures 14', value: 9, color: '#267c26' },
              { info: 'Lectures 4', value: 5, color: '#00acee' },
              { info: 'Lectures 8', value: 12, color: '#ffc201' },
              { info: 'Lectures 12', value: 9, color: '#ea3535' },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    redirectToStudentResultPage(resultObj) {
      this.$router?.push({
        name: 'exam-student-result',
        params: {
          examId: this.$route.params.examId,
          stdId: resultObj.student_id,
        },
      })
    },

    itemStyleStatus(status) {
      const style = {
        color: '',
        backgroundColor: '',
      }

      switch (status) {
        case 'pass':
          style.color = '#267C26'
          style.backgroundColor = '#E9F2E9'
          break
        case 'fail':
          style.color = '#EA3535'
          style.backgroundColor = '#FCE1E1'
          break
      }

      return style
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowUiModal(true)
          break
        case 'close':
          this.setShowUiModal(false)
          break
        case 'cancel':
          break
        case 'confirm':
          break
      }
    },

    async filterRecord(range) {
      const paginationLimit = {
        skip: range?.skip || 0,
        limit: range?.limit || 10,
        id: this.$route.params.examId,
      }
      const [res, err] = await this.examResult(paginationLimit)
      if (!err) {
        this.resultCount = res.data.meta.total_records
        this.resultsList = res.data.records.map((rec) => {
          rec.full_name = `${rec.first_name} ${rec.last_name}`
          return rec
        })
        this.skip = range?.skip || 0
        /* PREPARING STATS */
        const stats_info = []

        Object.entries(res.data.exam_stats).forEach(([key, value]) => {
          key = objectKeyIntoCapitalizeWords(key)
          if (key == 'Date') {
            value = getTimeFromDateWithUTC(value, TIME_CONSTANTS.DATE)
          }
          stats_info.push({ title: `${key} :`, data: value })
        })
        this.setRightBar(stats_info)
      }
    },
  },
}
</script>

<style lang="scss" module scoped>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
